import React from "react";
import "./App.css";
import { AppBar, Box, CssBaseline, Typography } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { Route, Redirect } from "react-router-dom";
import { About } from "./features/about/about";
import { projects } from "./features/projects/projects";
import { contact } from "./features/contact/contact";
import { NavTabs } from "./features/NavTabs/NavTabs";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#4f8287",
    },
    text: {
      primary: "#ffffff",
    },
    primary: {
      main: "#7e9da0",
    },
    secondary: {
      main: "#4f8287",
    },
  },
  typography: {
    fontFamily: [
      "Jockey One",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function App(props: any) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box position="absolute" width="100%" height="100%">
        {/*
        <AppBar position="static">
          <Toolbar>
            <Typography>ROBERT EGAN</Typography>
            <NavTabs />
          </Toolbar>
        </AppBar>
        */}
        <Box
          top="64px"
          width="100%"
          height="calc(100% - 64px)"
          justifyContent="center"
          alignItems="center"
        >
          <Route exact path="/">
            <Redirect to="/about" />
          </Route>
          <Route path="/about" component={About} />
          {/*<Route path="/projects" component={projects} />*/}
          {/*<Route path="/contact" component={contact} />*/}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
