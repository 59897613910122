import React from "react"; // we need this to make JSX compile

import { Typography, Box, Link } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { ContactItem } from "./ContactItem";

export const LinkedInContact = () => {
  const icon = (
    <Box p={0.5}>
      <LinkedInIcon fontSize="large" />
    </Box>
  );
  const value = (
    <Typography>
      <Link
        color="inherit"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.linkedin.com/in/rjegan"
      >
        https://www.linkedin.com/in/rjegan
      </Link>
    </Typography>
  );

  return <ContactItem icon={icon} value={value} />;
};
