import React from "react"; // we need this to make JSX compile
import { Avatar, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../img/RobertEganPic.png";
import { EmailContact } from "../contact/EmailContact";
import { LinkedInContact } from "../contact/LinkedInItem";
import { GitHubContact } from "../contact/GitHubContact";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(46),
    height: theme.spacing(46),
    boxShadow: theme.shadows[5],
  },
}));

export function About() {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Box p={3} bgcolor="#7e9da0" boxShadow={3} borderRadius={16}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Avatar alt="Robert Egan" src={image} className={classes.large} />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h3">Robert Egan</Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h6">Senior Software Engineer</Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body1">
            Hey, thanks for stopping by. Please call me Rob.
          </Typography>
        </Box>
        <EmailContact />
        <LinkedInContact />
        <GitHubContact />
      </Box>
    </Box>
  );
}
