import React from "react"; // we need this to make JSX compile

import { Box } from "@material-ui/core";

interface ContactItemProps {
  icon: any;
  value: any;
}

export const ContactItem = (props: ContactItemProps) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {props.icon}
      {props.value}
    </Box>
  );
};
