import React from "react"; // we need this to make JSX compile

import { Typography, Link, Box } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import { ContactItem } from "./ContactItem";

export const EmailContact = () => {
  const icon = (
    <Box p={0.5}>
      <EmailIcon fontSize="large" />
    </Box>
  );

  const value = (
    <Typography>
      <Link color="inherit" href="mailto:robert.egan@gmail.com">
        Robert.Egan@gmail.com
      </Link>
    </Typography>
  );

  return <ContactItem icon={icon} value={value} />;
};
