import React from "react"; // we need this to make JSX compile

import { Typography, Box, Link } from "@material-ui/core";
import GitHubIcon from "@material-ui/icons/GitHub";
import { ContactItem } from "./ContactItem";

export const GitHubContact = () => {
  const icon = (
    <Box p={0.5}>
      <GitHubIcon fontSize="large" />
    </Box>
  );
  const value = (
    <Typography>
      <Link
        color="inherit"
        target="_blank"
        rel="noopener noreferrer"
        href="https://github.com/RobertEgan"
      >
        https://github.com/RobertEgan
      </Link>
    </Typography>
  );
  return <ContactItem icon={icon} value={value} />;
};
